var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('heade'),_c('div',{staticClass:"com"},[_c('div',{staticClass:"com_box"},[_c('div',{staticClass:"com-left"},[_c('div',{staticClass:"com-left-top title",class:[
          _vm.titleLength <= 15 ? 'title-height-middle' : '',
          _vm.titleLength >= 16 && _vm.titleLength < 20 ? 'title-height-big' : '',
          _vm.titleLength >= 20 ? 'title-height-larger' : '',
        ]},[_vm._v(" "+_vm._s(_vm.praparation.name)+" ")]),_c('div',{staticClass:"com_title_box"},[_c('div',{staticClass:"com_title nice-scroll"},_vm._l((_vm.praparation.division_category),function(items,indexs){return _c('div',{key:indexs,staticClass:"com_title_list"},[_c('div',{staticClass:"com-left-foot"},[_c('div',{},_vm._l((items.division),function(itemc,indexc){return _c('div',{key:indexc,attrs:{"id":itemc.division_content_id},on:{"click":function($event){return _vm.getPraparationDivisionContentByContenId(
                      itemc.division_content_id,
                      itemc.charge_mode,
                      indexc
                    )}}},[_c('div',{directives:[{name:"trigger",rawName:"v-trigger"}],staticClass:"com-left-foot-top",class:_vm.queryWhere.id == itemc.division_content_id
                      ? 'addclass'
                      : '',attrs:{"id":'trigger' + indexc}},[_vm._v(" "+_vm._s(itemc.name)+" ")])])}),0)])])}),0)])]),_c('div',{staticClass:"com-right preparation"},[_c('van-tabs',{model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_c('van-tab',{attrs:{"title":"教学目标"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.mubiao)}})]),_c('van-tab',{attrs:{"title":"教学内容"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.kewen)}})]),(_vm.shuoming != '')?_c('van-tab',{attrs:{"title":"教学说明"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.shuoming)}})]):_vm._e(),(_vm.sheji != '')?_c('van-tab',{attrs:{"title":"教学设计"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.sheji)}})]):_vm._e(),_c('van-tab',{attrs:{"title":"相关素材"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.sucai)}})]),_c('van-tab',{attrs:{"title":"延伸学习"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.xuexi)}})])],1)],1),_c('div',{staticClass:"com-btt"},_vm._l((_vm.praparations),function(item,index){return _c('div',{key:index},[_c('router-link',{staticClass:"com-bbt-item",class:item.id == _vm.praparation.id ? 'com-bbt-item active2' : 'com-bbt-item',attrs:{"to":{
            path: '/preparation/detail',
            query: { id: item.id, manual_edition_id: 1 },
          }}},[_vm._v(_vm._s(item.name))])],1)}),0)])]),_c('foot')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }